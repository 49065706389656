.header {
  display: flex;
  align-items: center;

  height: var(--header-height);
  position: fixed;
  top: 0;
  background-color: var(--gray-bg);
  width: 100%;
  z-index: 3;
  padding: 0 1.875rem;
}

.header__logo {
  color: var(--logo);
}
