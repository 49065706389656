.errorpage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--gray-bg);
}

.errorpage__title {
  font-size: 7rem;
  color: var(--gray-text);
}

.errorpage__subtitle {
  margin: 2rem 0;
  font-size: 3rem;
  color: var(--gray-text);
}

.errorpage__link {
  font-size: 1.25rem;
  color: var(--gray-text);
  text-decoration: underline;
}
