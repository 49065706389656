.trigger {
  display: block;
  margin: .125rem 0 0 1.25rem;

  @media screen and (max-width: 62.1875rem) {
    margin-right: 2rem;
  }
}

.trigger__button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 1.125rem;
  width: 1.125rem;
  font-size: var(--font-18);
}
