.footer {
  background-color: var(--footer-bg);
  min-height: 15.9375rem;
  padding: 2rem 1.875rem 1.25rem;
  margin-top: auto;
}

.footer__row {
  display: flex;
  flex-wrap: wrap;
}

.footer__logowrapper {
  width: 100%;

  @media screen and (min-width: 62rem) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.footer__logo {
  color: #fff;
}

.footer__socials {
  margin-top: 1.25rem;
}

.footer__copyright {
  margin-top: .625rem;
  font-size: var(--font-10);
  color: #9E9E9E;
}
