.card {
  cursor: pointer;
}

.card:hover .card__link,
.card:hover .card__button {
  box-shadow: var(--shadow);
}

.card__2 .card__image,
.card__3 .card__image,
.card__4 .card__image {
  padding-bottom: 50%;
}

.card__2 .card__title {
  font-size: var(--font-22);
  line-height: 1.75rem;
}

.card__3 .card__title {
  font-size: var(--font-20);
  line-height: 1.375rem;
}

.card__4 .card__title {
  font-size: var(--font-18);
  line-height: 1.25rem;
}

.cardSmall .card__image {
  width: 6.25rem;
  height: 6.25rem;
  padding-bottom: 0;
  flex-shrink: 0;
}

.cardSmall .card__wrapper {
  display: flex;
}

.cardSmall .card__content {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: .625rem;
  min-height: 100%;
  height: 6.25rem;
}

.cardSmall .card__title {
  font-size: var(--font-16);
  line-height: 1.25rem;
}

.cardSmall .card__description {
  font-size: var(--font-12);
  line-height: 1.25rem;
}

.cardSmall .card__title,
.cardSmall .card__description {
  width: 90%;
}

.card__link {
  display: block;
  transition: 0.3s ease;
  text-decoration: none;
}

.card__wrapper {
  width: 100%;
  flex-basis: 100%;
  position: relative;
  transition: 0.3s ease;
}

.card__image {
  width: 100%;
  padding-bottom: 30%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
}

.card__content {
  width: 100%;
  min-height: 6.5rem;
  background-color: var(--nav-bg);
  padding: .3125rem 1.25rem;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cardTall .card__image {
  padding-bottom: 0;
  height: 100%;
}

.cardTall .card__wrapper {
  height: calc(100% - 6.5rem);
}

.cardTall .card__link {
  height: 100%;
}

.card__title {
  color: var(--name-text);
  font-size: var(--font-28);
  line-height: 2.5rem;
  margin-bottom: .5rem;
}

.card__description {
  color: var(--gray-text);
  font-size: var(--font-14);
  line-height: 1.25rem;
}

.card__dateWrapper {
  display: flex;
  align-items: center;
  margin-top: auto;
  color: var(--gray-text);
}

.card__date {
  font-size: var(--font-12);
  line-height: 1.25rem;
}

.card__dateIcon {
  margin-right: .3125rem;
}

.card__button {
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.3s ease;
  text-decoration: none;
  border: none;
  padding: 0;
  font-family: inherit;
  text-align: left;
  cursor: pointer;
}

@media screen and (max-width: 61.9375rem) {
  .card__image {
    padding-bottom: 50%;
  }

  .card__title,
  .card__2 .card__title, 
  .card__3 .card__title {
    font-size: var(--font-20);
    line-height: 1.375rem;
  }

  .cardSmall .card__title {
    font-size: var(--font-16);
    line-height: 1.25rem;
  }
}

@media screen and (max-width: 55rem) {
  .cardSmall .card__title {
    font-size: var(--font-14);
  }
  
  .cardSmall .card__description {
    font-size: var(--font-12);
    line-height: .875rem;
  }
}

@media screen and (max-width: 75rem) {
  .cardTall .card__image {
    padding-bottom: 40%;
  }
}
