@import '~@csstools/normalize.css/normalize.css';
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=1e12f568-8eb7-4554-a319-900ed4700bd2&fontids=5664085,5664103");

@font-face{
  font-family:"Helvetica Neue LT W05 45 Light";
  src:url("./assets/fonts/5664085/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),url("./assets/fonts/5664085/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}
@font-face{
  font-family:"Helvetica Neue LT W05 65 Medium";
  src:url("./assets/fonts/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),url("./assets/fonts/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}

:root {
  --gray-bg: #E8E8E8;
  --body-bg: #ffffff;
  --nav-bg: #ffffff;
  --page-bg: #E8E8E8;
  --footer-bg: #1E1E1E;
  --preview-bg: #1E1E1E;

  --text: #ffffff;
  --nav-text: #1E1E1E;
  --shadow: rgb(30 30 30 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  --logo: #1E1E1E;
  --name-text: #1E1E1E;
  --gray-text: #1E1E1E;

  --font-9: .5625rem;
  --font-10: .625rem;
  --font-11: .6875rem;
  --font-12: .75rem;
  --font-13: .8125rem;
  --font-14: .875rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-20: 1.25rem;
  --font-22: 1.375rem;
  --font-28: 1.75rem;
  --font-30: 1.875rem;
  --font-32: 2rem;

  --inner-width: 84.375rem;
  --header-height: 3.125rem;

  --font-light: "Helvetica Neue LT W05 45 Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-medium: "Helvetica Neue LT W05 65 Medium", "Helvetica Neue", Helvetica;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.dark {
  --gray-bg: #343434;
  --body-bg: #1E1E1E;
  --nav-bg: #343434;
  --page-bg: #1E1E1E;
  --footer-bg: #343434;
  --preview-bg: #E8E8E8;

  --shadow: #000000 0px 50px 100px -20px, #000000 0px 30px 60px -30px;
  --text: #1E1E1E;
  --nav-text: #9E9E9E;
  --gray-text: #9E9E9E;
  --logo: #ffffff;
  --name-text: #ffffff;
}

.footer {
  --name-text: #ffffff;
}

@media screen and (max-width: 995px) {
  :root {
    --nav-text: #ffffff;
  }

  .dark {
    --nav-text: #ffffff;
  }
}

body {
  margin: 0;
  font-family: var(--font-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--page-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

strong {
  font-family: var(--font-medium);
  font-weight: 500;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	border: 0;
	vertical-align: baseline;
}
