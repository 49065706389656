.badge {
  position: absolute;
  top: 0;
  right: 0;

  padding: .3125rem;
  background-color: var(--nav-bg);

  font-size: var(--font-9);
  font-family: var(--font-medium);
  letter-spacing: .025rem;
  color: var(--name-text);

  z-index: 2;
}
