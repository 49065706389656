.articleHero {
  display: grid;
  grid-template-columns: 66% 1fr;
  gap: 1.75rem;
  padding: 1.25rem 0;
}

.articleHeroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.articleHeroImageWrapper {
  height: 30.25rem
}

.articleHero__small {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 1.75rem;
}

@media screen and (max-width: 75rem) {
  .articleHero {
    grid-template-columns: 1fr;
  }

  .articleHero__small {
    grid-template-rows: auto;
  }

  .articleHeroImageWrapper {
    height: 40vh;
  }
}
