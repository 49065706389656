.videoModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 4.5rem;
}

.videoModal__content {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: var(--body-bg);
}

.videoModal__iframe {
  width: 100%;
  height: 100%;

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  source, video {
    width: 100%;
    height: 100%;
  }
}

.videoModal__button {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-family: var(--font-light);
  font-size: var(--font-16);

  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 2rem;

  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.videoModal__button:hover {
  opacity: 0.8;
}

.videoModal__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 3rem;
  height: 3rem;
  border: 5px solid #555;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  z-index: 0;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

@media screen and (max-width: 49.125rem) {
  .videoModal {
    padding: 3rem 2rem;
  }

  .videoModal__button {
    top: 0.5rem;
    right: 0.5rem;
    background-color: #fff;
  }
}
