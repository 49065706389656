.articleList {
  width: 100%;
  padding: .625rem 1.875rem;
  min-height: calc(100vh - 18.75rem);
  max-width: 84.375rem;
  margin: 0 auto;
}

.articleListItems {
  display: flex;
  flex-wrap: wrap;
  padding: 1.25rem 0;

  list-style-type: none;
}

.articleListItem:first-child {
  flex-basis: 100%;
}

.articleListItem:nth-child(2) {
  flex-basis: 50%;
}

.articleListItem:nth-child(3) {
  flex-basis: 50%;
}

.articleListItem {
  flex-basis: calc(100% / 3);
  flex-grow: 0;
  padding: 1rem;
}

@media screen and (max-width: 55rem) {
  .articleList {
    padding: .625rem .9375rem;
  }

  .articleListItem,
  .articleListItem:nth-child(2),
  .articleListItem:nth-child(3) {
    flex-basis: 100%;
  }
}
