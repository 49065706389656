.blocks {
  width: 100%;
  padding: .625rem 1.875rem;
  min-height: calc(100vh - 18.75rem);
  max-width: 84.375rem;
  margin: 0 auto;
}

@media screen and (max-width: 55rem) {
  .blocks {
    padding: .625rem .9375rem;
  }
}
