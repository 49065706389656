.banner {
  width: 100%;
  height: 7.8125rem;
  margin: 1.875rem 0;
  position: relative;
  transition: 0.3s;
  
  background-color: #FFF;

  cursor: pointer;

  &:hover {
    box-shadow: var(--shadow);
  }
}

.bannertall {
  height: 15.625rem;
}

.banner__link {
  height: 100%;
  display: flex;

  text-decoration: none;
}

.banner__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
  display: inline-block;
}

.banner__button {
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.3s ease;
  text-decoration: none;
  border: none;
  padding: 0;
  font-family: inherit;
  text-align: left;
  cursor: pointer;
}

.banner__content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.banner__icon {
  width: 4.75rem;
  height: 4.75rem;
  background-color: transparent;
  margin-left: 3.125rem;
  z-index: 2;
  object-fit: cover;

  @media screen and (max-width: 55rem) {
    display: none;
  }
}

.banner__head {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
  z-index: 1;
  color: #1E1E1E;

  @media screen and (min-width: 55rem) {
    width: 45%;
  }
}

.banner__title {
  font-size: var(--font-30);
  margin: 0.5rem;

  @media screen and (max-width: 55rem) {
    font-size: var(--font-22);
    line-height: 1.625rem;
  }
}

.banner__description {
  font-size: var(--font-14);
  margin: 0.5rem;

  @media screen and (max-width: 55rem) {
    font-size: var(--font-12);
    line-height: 1.125rem;
  }
}

.bannerLight .banner__head,
.bannerLight .banner__description {
  color: #9E9E9E;
}
