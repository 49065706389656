.sociallinks {
  display: flex;
  align-items: center;
  gap: 1.25rem;

  list-style-type: none;
}

.sociallinks__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
