.usertext {
  @media screen and (max-width: 59.6875rem) {
    display: none;
  }
}

.usertext__title {
  font-size: var(--font-22);
  color: var(--name-text);
  font-family: var(--font-medium);
  line-height: 1.27;
}

.usertext__description {
  margin-top: .875rem;

  font-size: var(--font-12);
  color: var(--gray-text);
}
