.iconlinks {
  margin-left: auto;

  display: flex;
  align-items: center;
  gap: 1.5rem;

  list-style-type: none;

  @media screen and (max-width: 30rem) {
    overflow: auto hidden;
    padding-bottom: 1rem;
  }
}

.iconlinks__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: var(--font-12);
  font-family: var(--font-medium);
  text-decoration: none;
  color: var(--name-text);

  @media screen and (max-width: 30rem) {
    flex-shrink: 0;
  }
}

.iconlinks__icon {
  height: 1.375rem;
  width: 1.375rem;
  margin-bottom: .5rem;
}

.iconlinks__icon img {
  width: 100%;
  height: auto;
}
