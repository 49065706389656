.sociallink {
  font-size: 1.25rem;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.sociallink__icon {
  width: 1.25rem;
  height: 1.25rem;
}
