.singleArticle {
  width: 100%;
  padding: .625rem 3.75rem 2rem;
  min-height: calc(100vh - 18.75rem);
  max-width: 84.375rem;
  margin: 0 auto;
}

.singleArticleContent {
  max-width: 52.5rem;
}

.singleArticleTitle {
  color: var(--gray-text);
  font-size: var(--font-32);
  font-weight: 600;
  margin-top: 1.25rem;
}

.singleArticleTime {
  display: flex;
  align-items: center;
  color: var(--gray-text);
  font-size: var(--font-12);
  margin: .75rem 0;
}

.singleArticleDate {
  margin-left: .3125rem;
}

.singleArticleContentWysiwyg {
  * {
    margin: 1rem 0;
    line-height: 1.5;
    color: var(--name-text);
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  strong,
  b {
    font-weight: 600;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.17rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.83rem;
  }

  h6 {
    font-size: 0.67rem;
  }

  ul {
    padding-left: 1rem;
  }

  ol {
    padding-left: 1rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.singleArticleReturn {
  color: var(--gray-text);
  text-decoration: none;
  font-weight: 700;

  margin: 3rem 0 1rem;
  display: block;
}

.singleArticleReturnArrow {
  margin-right: 0.5rem;
}

@media screen and (max-width: 55rem) {
  .singleArticle {
    padding: .625rem .9375rem;
  }


  .singleArticleReturn {
    margin: 3rem 0 2rem;
  }
}
