.groupName {
  padding-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupName__title {
  font-size: var(--font-16);
  font-family: var(--font-medium);
  color: var(--gray-text);
}

.groupName__link {
  font-size: var(--font-11);
  font-family: var(--font-medium);
  color: var(--gray-text);
  
  text-transform: uppercase;
  text-decoration: none;
}
