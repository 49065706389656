.navigation {
  margin-left: auto;
}

.navigation__nav {
  display: flex;
  align-items: center;

  @media screen and (max-width: 62.1875rem) {
    display: none;
    position: fixed;
    top: 2.75rem;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: #1E1E1E;
    color: #FFF;
    padding: 0 1.875rem 2.5rem;
  }
}

.isMenuOpen.navigation__nav {
  display: block;
}

.navigation__list {
  display: flex;
  align-items: center;
  margin-block-start: .25rem;
  margin-block-end: .25rem;

  list-style-type: none;

  @media screen and (max-width: 62.1875rem) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.navigation__item {
  @media screen and (max-width: 62.1875rem) {
    margin-top: 1.25rem;
  }
}

.navigation__link {
  color: var(--nav-text);
  text-decoration: none;
  font-size: var(--font-13);

  &:hover {
    text-decoration: underline;
  }
}

.navigation__divider {
  color: var(--nav-text);
  font-size: var(--font-12);
  margin: .25rem .3125rem 0;

  @media screen and (max-width: 62.1875rem) {
    display: none;
  }
}

.navigation__hamburger {
  display: none;

  @media screen and (max-width: 62.1875rem) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.navigation__hamburgerButton {
  width: 2.75rem;
  height: 2.75rem;
  background-color: #1E1E1E;
  padding: .625rem .875rem;
  border: none;
  cursor: pointer;
}

.navigation__hamburgerLine {
  transform: rotate(0deg);
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin: 4px 0;
  transition: all .2s ease-in-out;
}

.isHamburgerOpen .navigation__hamburgerLine {
  &:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 5px);
  }

  &:nth-child(2) {
    width: 0;
    opacity: 0;
  }

  &:nth-child(3) {
    transform: rotate(45deg) translate(-3px, -4px);
  }
}
