.footerlinks {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(3, 1fr);
  margin: 1rem 0;

  list-style-type: none;
  
  max-width: 100%;
  flex: 0 0 100%;

  @media screen and (min-width: 62rem) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.footerlinks__item {
  font-size: var(--font-12);
  line-height: 1.625rem;
}

.footerlinks__link {
  color: var(--name-text);
  text-decoration: none;
}
