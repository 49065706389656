.topnavigation {
  margin-top: var(--header-height);
  padding: 1.25rem 1.875rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  background-color: var(--nav-bg);

  @media screen and (max-width: 30rem) {
    padding: 1.25rem 0.9375rem .25rem;
  }
}
