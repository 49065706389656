.cardsgrid {
  display: grid;
  gap: 2rem;
  padding: 1.25rem 0;
}

.cardsgridSmall {
  gap: 1.25rem 2rem;
}

@media screen and (min-width: 48rem) {
  .cardsgridSmall {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 62rem) {
  .cardsgrid__2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .cardsgrid__3 {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .cardsgrid__4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .cardsgridSmall {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 75rem) {
  .cardsgridSmall {
    grid-template-columns: repeat(3, 1fr);
  }
}
