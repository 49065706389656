.previewMode {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--preview-bg);
  color: var(--text);
  padding: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
