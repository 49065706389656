.mixedCards {
  display: grid;
  grid-template-columns: 66% 1fr;
  gap: 1.75rem;
  padding: 1.25rem 0;
}

.mixedCards__small {
  display: grid;
  gap: 1.75rem;
}

@media screen and (max-width: 75rem) {
  .mixedCards {
    grid-template-columns: 1fr;
  }
}
